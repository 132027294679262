.pure-table {
  table {
    margin: 0;
    table-layout: fixed;

    thead {
      tr {
        background-color: #e6ebf2;

        th {
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 500;
          vertical-align: middle;
          color: #707070;
          border: none !important;
        }
      }
    }

    tbody {
      tr {
        background-color: white;

        td {
          text-overflow: ellipsis;
          overflow: hidden;
          vertical-align: middle;
          color: #333333;
          padding: 0.65rem !important;
        }

        &:hover {
          background-color: #eff3f9;

          td:first-child {
            position: relative;
          }

          td:first-child::before {
            content: '';
            width: 5px;
            background-color: var(--color-primary);
            display: block;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }

  .pagination-container {
    margin-bottom: 5%;

    .previous-page,
    .next-page {
      cursor: pointer;
    }
  }
}

.expanded-row-header {
  background: #e6ebf2 !important;
}

.export {
  float: right;
  position: relative;
  bottom: 20px;
  right: 30px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  width: 50px;
  height: 35px;
  transition: width 0.6s ease;

  span {
    transition: opacity 0.3s ease-out, transform 0.5s ease-out;

    &:before {
      position: absolute;
      left: 0;
      top: 100%;
      //opacity: 0;
      transform: translateY(0);
    }
  }

  &:hover {
    right: 15px;
    padding-right: 25px;
    padding-top: 12px;
    width: 80px;
    border: 1px solid var(--color-primary);
    border-radius: 15px;
    background-color: var(--color-white);

    .export-icon {
      opacity: 0;
    }

    span {
      transform: translateY(-100%);

      &:before {
        content: attr(data-hover);
        color: var(--color-primary);
        opacity: 1;
        font-size: medium;
        font-weight: bold;
      }
    }
  }
}
.loader {
  position: absolute;
  z-index: 10002;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #070707bf;
}
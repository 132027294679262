.poc-form-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;


    & label {
        padding-bottom: 2px;
    }
}

.react-datepicker-wrapper {
    width: 100%
}